<script>
import appConfig from "@/app.config";
import MetisMenu from "metismenujs/dist/metismenujs";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "maestro",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      title: "maestro",
      isLoading: false,
      menuClosed: false,
    };
  },
  created() {},
  beforeDestroy() {},
  mounted() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#maestro-side-menu");
  },
  methods: {
    toggleMenu() {
      this.menuClosed = !this.menuClosed;
    },
  },
};
</script>

<template>
  <div
    id="maestro-page"
    style="display: flex; flex-direction: column"
    :class="{ '': menuClosed, 'maestro-menu-closed': menuClosed }"
  >
    <div id="maestro-sidebar-menu" v-if="!menuClosed">
      <div style="display: flex">
        <h4 style="margin-right: auto">{{ $t("general.maestro") }}</h4>
        <i
          id="closeMaestroMenuBtn"
          @click="toggleMenu()"
          style="cursor: pointer; font-size: 1.5em; margin-right: 8px"
          class="fa fa-fw fa-bars ml-3"
        ></i>
      </div>

      <ul id="maestro-side-menu" class="metismenu list-unstyled">
        <li>
          <router-link
            to="/configuration/maestro/progress-prioritization"
            zzclass="side-nav-link-ref"
            style="display: flex; align-items: center"
          >
            <span class="fas fa-list-ol mr-2" style="font-size: 1rem"></span>
            <span>{{ $t("general.prioritiesCalculation") }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            to="/configuration/maestro/merged-progress-prioritization"
            zzclass="side-nav-link-ref"
            style="display: flex; align-items: center"
          >
            <i class="mdi mdi-merge mr-2" style="font-size: 1rem"></i>
            <span>{{ $t("general.mergedProgress") }}</span>
          </router-link>
        </li>

        <li>
          <router-link
            to="/configuration/maestro/thresholds"
            zzclass="side-nav-link-ref"
            style="display: flex; align-items: center"
          >
            <span class="fas fa-bullseye mr-2" style="font-size: 1rem"></span>
            <span>{{ $t("general.thresholds") }}</span>
          </router-link>
        </li>
        <li>
          <router-link
            to="/configuration/maestro/maintenances"
            zzclass="side-nav-link-ref"
            style="display: flex; align-items: center"
          >
            <i class="mdi mdi-hammer-wrench mr-2" style="font-size: 1rem"></i>
            <span>{{ $t("general.maintenances") }}</span>
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Content -->
    <div
      id="maestro-content"
      style="padding: 0; flex: 1 1 1px; display: flex; flex-direction: column"
    >
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "@/design/_variables.scss";
.router-link-active {
  color: #1f3dd0;
}
#maestro-page {
  flex-grow: 1;
}
#maestro-sidebar-menu {
  width: 260px;
  top: $header-height;
  bottom: 0;
  background-color: #eaeaea;
  position: fixed;
  padding: 13px;
  padding-top: 24px;
  z-index: 1;
}

#maestro-content {
  margin-left: 260px;
  padding: 24px;
}
</style>
