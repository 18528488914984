<script>
import { Sketch } from 'vue-color'

export default {
  name: "thresholds",
  page: {
    title: "thresholds"
  },
  props: {
    thresholdConfig: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      required: true,     
    }
  },  
  components: {
    Sketch
  },  
  data(){
    return {
      showColorPicker: false
    }
  },
  created(){
  },   
  mounted() {},
  methods: {
    updateColorThreshold(color){
      this.thresholdConfig.colorHexa = color.hex;
    },
    toggleColorPicker(){
      this.showColorPicker = !this.showColorPicker;
    }
  }
};
</script>

<template>
  <div class="card mb-1">
    <div class="card-body">
      <h4 class="card-title mb-3">{{this.title}}</h4> 
      <div class="mb-2" style="display:flex; align-items:center;">
        <div class="mr-2" :style="{'background': thresholdConfig.colorHexa}" style="width:27px; height:27px; border-radius:5px;"></div>
        <b-button @click="toggleColorPicker()" v-show="!showColorPicker" size="sm" variant="primary">{{$t('general.selectColor')}}</b-button>
        <b-button @click="toggleColorPicker()" v-show="showColorPicker" size="sm" variant="primary">{{$t('general.close')}}</b-button>
      </div>
      <Sketch style="position:absolute;" v-show="showColorPicker" :value="thresholdConfig.colorHexa" @input="updateColorThreshold" />
      <div style="display:flex;">
        <b-form-group class="mb-0">
          <label>Min(&gt;=)</label>
          <b-form-input
            type="number"
            class="mr-3"
            v-model="thresholdConfig.min"
            placeholder="0"
            style="max-width:90px; height:38px;">
          </b-form-input>
        </b-form-group> 
        <b-form-group class="mb-0">
          <label>Max(&lt;=)</label>
          <b-form-input
            type="number"
            v-model="thresholdConfig.max"
            placeholder="0"
            style="max-width:90px; height:38px;">
          </b-form-input>
        </b-form-group> 
      </div>
    </div>
  </div>
</template>