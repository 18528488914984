<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import MaestroMenuLayout from "../components/maestro-menu-layout";
import CalendarApiService from "@/services/calendarService";
import ThresholdConfiguration from "./components/threshold-configuration";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "critical ratio",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    MaestroMenuLayout,
    ThresholdConfiguration
  },
  data() {
    let thresholdLateColor = '#343a40';
    let thresholdUrgentColor = '#f46a6a';
    let thresholdAttentionColor = '#f1b44c';
    let thresholdNormalColor = '#34c38f';

    return {
      title: "critical ratio",
      isLoading: false,        
      schedulingThresholdConfig: {
        criticalRatio: {
          regular:{
            colorHexa: thresholdNormalColor,
            min: 0,
            max: 0
          },
          attentionRequired:{
            colorHexa: thresholdAttentionColor,
            min: 0,
            max: 0
          },
          urgent:{
            colorHexa: thresholdUrgentColor,
            min: 0,
            max: 0            
          },
          late:{
            colorHexa: thresholdLateColor,
            min: 0,
            max: 0            
          }
        },
        deliveryDate: {
          regular:{
            colorHexa: thresholdNormalColor,
            min: 0,
            max: 0
          },
          attentionRequired:{
            colorHexa: thresholdAttentionColor,
            min: 0,
            max: 0
          },
          urgent:{
            colorHexa: thresholdUrgentColor,
            min: 0,
            max: 0            
          },
          late:{
            colorHexa: thresholdLateColor,
            min: 0,
            max: 0            
          }
        },
        installationDate: {
          regular:{
            colorHexa: thresholdNormalColor,
            min: 0,
            max: 0
          },
          attentionRequired:{
            colorHexa: thresholdAttentionColor,
            min: 0,
            max: 0
          },
          urgent:{
            colorHexa: thresholdUrgentColor,
            min: 0,
            max: 0            
          },
          late:{
            colorHexa: thresholdLateColor,
            min: 0,
            max: 0            
          }         
        },
        productionDate: {
          regular:{
            colorHexa: thresholdNormalColor,
            min: 0,
            max: 0
          },
          attentionRequired:{
            colorHexa: thresholdAttentionColor,
            min: 0,
            max: 0
          },
          urgent:{
            colorHexa: thresholdUrgentColor,
            min: 0,
            max: 0            
          },
          late:{
            colorHexa: thresholdLateColor,
            min: 0,
            max: 0            
          }
        },
        operationDate: {
          regular:{
            colorHexa: thresholdNormalColor,
            min: 0,
            max: 0
          },
          attentionRequired:{
            colorHexa: thresholdAttentionColor,
            min: 0,
            max: 0
          },
          urgent:{
            colorHexa: thresholdUrgentColor,
            min: 0,
            max: 0            
          },
          late:{
            colorHexa: thresholdLateColor,
            min: 0,
            max: 0            
          }        
        }
      }
    };
  },
  created (){
    CalendarApiService.getSchedulingThresholdsConfig().then((response) => {
      var config = response.data.data;
      if(config != null){
        this.schedulingThresholdConfig = config;
      }
    })
  },
  mounted() {},
  methods: {
    toggleSchedulingMenu(){
      this.$root.$emit('toggle-scheduling-menu');
    },         
    saveConfig(){

      CalendarApiService.saveSchedulingThresholdsConfig(this.schedulingThresholdConfig).then(() => {
        //toast success
        this.$bvToast.toast(this.$t('general.schedulingThresholdSavedContent'), {
          title: this.$t('general.saved'),
          autoHideDelay: 5000,
          appendToast: false,
          variant: 'success',
          solid: true
        })
      })
    }
  }
};
</script>

<template>
  <Layout :contentPadding="false">  
    <MaestroMenuLayout>
      <div style="flex:1 1 1px; display:flex; flex-direction:column;">
        <div id="thresholdsTopPart" class="pb-0" style="padding:24px; ">
          <div style="display:flex;">
            <i @click="toggleSchedulingMenu()" style="cursor: pointer; font-size: 1.5em; margin-right: 8px;" class="fa fa-fw fa-bars"></i>
            <PageHeader :title="$t('general.thresholds')" />
          </div>           
          <div class="p-0">
            <div style="padding: 20px; background: white; border-bottom: 1px solid rgba(204,204,204,.8);">             
              <b-button @click="saveConfig" variant="success" class="mr-2">{{$t('general.save')}}</b-button>
            </div>
          </div>
        </div>

        <div id="thresholdsBottomPart" class="pt-0" style="padding:24px; flex:1 1 1px; min-height:0; overflow-y:auto;">
          <!--<ThresholdConfiguration :title="$t('general.criticalRatio')" :thresholdConfig="schedulingThresholdConfig.criticalRatio" />
          <hr>-->
          <ThresholdConfiguration :title="$t('general.deliveryDate')" :thresholdConfig="schedulingThresholdConfig.deliveryDate" />
          <hr>
          <ThresholdConfiguration :title="$t('general.installationDate')" :thresholdConfig="schedulingThresholdConfig.installationDate" />
          <hr>
          <ThresholdConfiguration :title="$t('general.productionDate')" :thresholdConfig="schedulingThresholdConfig.productionDate" />
          <hr>
          <ThresholdConfiguration :title="$t('general.operationDate')" :thresholdConfig="schedulingThresholdConfig.operationDate" />
        </div>
      </div>
    </MaestroMenuLayout>

  </Layout>
</template>
