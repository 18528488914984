<script>
import ThresholdMinMaxComponent from "./components/threshold-min-max";

export default {
  name: "thresholds",
  page: {
    title: "thresholds"
  },
  props: {
    title:{
      type: String,
      required: true,
    },
    thresholdConfig: {
      type: Object,
      required: true,
    },
  },  
  components: {
    ThresholdMinMaxComponent
  },
  data(){
    return {
      containerOpened: false
    }
  },
  created(){

  },   
  mounted() {

  },
  methods: {

  }
};
</script>

<template>
  <div class="mt-4">
    <div style="cursor:pointer;" class="row" @click="containerOpened = !containerOpened">
      <div class="col-12" style="display:flex; align-items:center;">
        <h5 class="m-0">{{title}}</h5>
        <i style="margin-left:auto; font-size:2em;" v-bind:class="{'fas fa-angle-down': !containerOpened,  'fas fa-angle-up': containerOpened}"></i>
      </div>    
    </div>

    <b-collapse v-model="containerOpened" class="mt-3">
      <div class="row">
        <div class="col-6 col-md-3">
          <ThresholdMinMaxComponent :title="$t('general.regular')" :thresholdConfig="thresholdConfig.regular"></ThresholdMinMaxComponent>
        </div> 

        <div class="col-6 col-md-3">
          <ThresholdMinMaxComponent :title="$t('general.attentionRequired')" :thresholdConfig="thresholdConfig.attentionRequired"></ThresholdMinMaxComponent>
        </div> 

        <div class="col-6 col-md-3">
          <ThresholdMinMaxComponent :title="$t('general.urgent')" :thresholdConfig="thresholdConfig.urgent"></ThresholdMinMaxComponent>
        </div> 

        <div class="col-6 col-md-3">
          <ThresholdMinMaxComponent :title="$t('general.late')" :thresholdConfig="thresholdConfig.late"></ThresholdMinMaxComponent>
        </div>      

      </div>
    </b-collapse>
  </div>
</template>